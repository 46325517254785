import * as React from "react";

import { Layout } from "../components/layout";

const NotFoundPage = () => (
  <Layout>
    <h1>Erreur 404</h1>
    <p>On n'a pas trouvé la page que tu voulais...</p>
  </Layout>
);

export default NotFoundPage;
